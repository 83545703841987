import { Role } from 'generated/schema-types';
import { FunctionComponent, useContext } from 'react';
import UserContext from '../context/UserContext';
import permissions, { Action } from '../rbac-rules';

export const hasPermission = (
  role: Role | undefined,
  actions: Action | Action[],
  data?: Record<string, string>
): boolean => {
  if (!role) {
    return false;
  }

  const rules = permissions[role];

  if (!rules) {
    // role is not present in the rules
    return false;
  }

  // Check recursively if an implemented role permits action (DANGER - EXPERIMENTAL)
  if (rules.implements) {
    for (let i = 0; i < rules.implements.length; i++) {
      const implementedRole = rules.implements[i];
      if (hasPermission(implementedRole, actions, data)) {
        return true;
      }
    }
  }

  const staticPermissions = rules.static;
  const dynamicPermissions = rules.dynamic;

  if (Array.isArray(actions)) {
    if (staticPermissions) {
      // Check if every action is in included in static permisisions
      // Note: changing every for `some` will have an OR behavior
      const hasAny = actions.every(one => {
        return staticPermissions.includes(one);
      });
      if (hasAny) return true; // Don't return on false because we need to check the dynamic
    }
    for (let i = 0; i < actions.length; i++) {
      const action = actions[i];
      if (dynamicPermissions) {
        const dynamicRuleCondition = dynamicPermissions[action];
        if (!dynamicRuleCondition) {
          return false;
        }
        return data ? dynamicRuleCondition(data) : false;
      }
    }
  } else {
    const action = actions as Action;
    // Check if any of the user permissions do a match
    if (staticPermissions && staticPermissions.some(permission => permission === action)) {
      // Don't return on false because we need to check the dynamic
      return true;
    }
    if (dynamicPermissions) {
      const permissionCondition = dynamicPermissions[action];
      if (!permissionCondition) {
        // dynamic rule not provided for action
        return false;
      }
      return data ? permissionCondition(data) : false;
    }
  }

  // Deny permission as fallback
  return false;
};

interface CanProps {
  role?: Role;
  do: Action | Action[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  no?: any;
  data?: Record<string, string>;
}

const Can: FunctionComponent<CanProps> = props => {
  const user = useContext(UserContext);
  if (user) return hasPermission(user?.role, props.do, props.data) ? props.children : props.no;
};

Can.defaultProps = {
  no: null
};

export default Can;
